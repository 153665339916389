import { Link } from "gatsby"
import _ from "lodash"
import React, { useState } from "react"
import { AspectRatio, Box, Grid, Heading } from "theme-ui"

import BlockContainer from "./block-container"
import BlockTitle from "./block-title"

const TeamMemberCard = ({
  teamMember,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Link
      onMouseOver={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      to={`/team-members/${teamMember?.slug}`}
    >
      <AspectRatio ratio={1 / 1}>
        <img
          alt={teamMember?.name}
          src={teamMember?.photo?.resize?.src}
          style={{
            height: "100%",
            objectFit: "cover",
            transform: isHovered
              ? "scale(1.25) translateY(10%)"
              : "scale(1.0) translateY(0%)",
            transition: "400ms",
            width: "100%",
          }}
        />
        <Box
          sx={{
            alignItems: "center",
            bg: "rgba(255, 255, 255, 0.8)",
            bottom: "0px",
            display: "flex",
            justifyContent: "center",
            left: "0px",
            opacity: isHovered ? 1 : 0,
            position: "absolute",
            right: "0px",
            top: "0px",
            transition: "200ms",
          }}
        >
          <Heading as="h2" variant="h2">
            {teamMember?.name}
          </Heading>
        </Box>
      </AspectRatio>
    </Link>
  )
}

const spacer = 5

const marginTops = [
  spacer, 0, spacer, 0, spacer,
  spacer, 0, spacer, 0, spacer,
  spacer, 0, spacer, 0, spacer,
  spacer, 0, spacer, 0, spacer,
  spacer, 0, spacer, 0, spacer,
  spacer, 0, spacer, 0, spacer,
  spacer, 0, spacer, 0, spacer,
]

const marginBottoms = [
  0, spacer, 0, spacer, 0,
  0, spacer, 0, spacer, 0,
  0, spacer, 0, spacer, 0,
  0, spacer, 0, spacer, 0,
  0, spacer, 0, spacer, 0,
  0, spacer, 0, spacer, 0,
  0, spacer, 0, spacer, 0,
]

const ContentfulTeamMembersBlock = ({
  teamMembers,
}) => {
  return (
    <BlockContainer sxOverride={{ pr: [0, 0, 3, 3] }}>
      <BlockTitle title="Our team" />
      <Box
        sx={{
          mt: [4, 4, 5, 5],
        }}
      >
        <Grid columns={[2, 2, 5, 5]} gap={0}>
          {teamMembers?.map((teamMember, index) => (
            <Box key={`team-${index}`} sx={{ 
              pt: [0, marginTops[index]],
              pb: [0, marginBottoms[index]]
            }}>
              <TeamMemberCard teamMember={teamMember} />
            </Box>
          ))}
        </Grid>
      </Box>
    </BlockContainer>
  )
}

export default ContentfulTeamMembersBlock
