import { ReplaceComponentRendererArgs } from "gatsby"
import React from "react"

import ContentfulFooterBlock from "../components/contentful-footer-block"
import ContentfulFullscreenPictureBlock from "../components/contentful-fullscreen-picture-block"
import ContentfulHowWeDoBlock from "../components/contentful-how-we-do-block"
import ContentfulIndustriesBlock from "../components/contentful-industries-block"
import ContentfulSingleSlideBlock from "../components/contentful-single-slide-block"
import ContentfulTeamMembersBlock from "../components/contentful-team-members-block"
import ContentfulTextHeroBlock from "../components/contentful-text-hero-block"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const AboutPagePage = ({
  pageContext,
}) => {
  const aboutPage = pageContext.node

  return (
    <>
       <GatsbySeo noindex={false} canonical="https://www.obviouslee.com/about" />
      <SEO
        description={aboutPage.metaDescription?.metaDescription}
        title={aboutPage.metaTitle}
      />
      <Layout headerLabel={aboutPage.headerLabel}>
        <ContentfulTextHeroBlock
          body={aboutPage.heroBody?.heroBody}
          headline={aboutPage.heroHeadline?.heroHeadline}
        />
        <ContentfulFullscreenPictureBlock picture={aboutPage.picture1} />
        <ContentfulHowWeDoBlock
          items={aboutPage.howWeDoItems}
          name={aboutPage.howWeDoTitle}
          secretLink={aboutPage.howWeDoSecretLink}
        />
        <ContentfulTeamMembersBlock
          teamMembers={aboutPage.teamMembers}
        />
        <ContentfulIndustriesBlock
          industry1Description={
            aboutPage.industry1Description?.industry1Description
          }
          industry1Name={aboutPage.industry1Name}
          industry1Tags={aboutPage.industry1Tags}
          industry2Description={
            aboutPage.industry2Description?.industry2Description
          }
          industry2Name={aboutPage.industry2Name}
          industry2Tags={aboutPage.industry2Tags}
          industry3Description={
            aboutPage.industry3Description?.industry3Description
          }
          industry3Name={aboutPage.industry3Name}
          industry3Tags={aboutPage.industry3Tags}
          industry4Description={
            aboutPage.industry4Description?.industry4Description
          }
          industry4Name={aboutPage.industry4Name}
          industry4Tags={aboutPage.industry4Tags}
        />
        <ContentfulSingleSlideBlock
          body={aboutPage.ctaBody?.ctaBody}
          headline={aboutPage.ctaHeadline?.ctaHeadline}
          picture={aboutPage.ctaPicture}
        />
        <ContentfulFooterBlock
          address={aboutPage.footer?.address?.address}
          backgroundColor={aboutPage.footer?.backgroundColor}
          copyright={aboutPage.footer?.copyright?.copyright}
          socialLinksTitle={aboutPage.footer?.socialLinksTitle}
          blogTitle={aboutPage.footer?.blogTitle}
          newsletterJoinTitle={aboutPage.footer?.newsletterJoinTitle}
          facebookLink={aboutPage.footer?.facebookLink}
          form={aboutPage.footer?.form}
          gif={aboutPage.footer?.gIf}
          gifDescription={aboutPage.footer?.gifDescription}
          instagramLink={aboutPage.footer?.instagramLink}
          linkedInLink={aboutPage.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export default AboutPagePage
