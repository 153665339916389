import React from "react"
import { Box, Flex, Heading, Text } from "theme-ui"

import BlockContainer from "./block-container"

const ContentfulTextHeroBlock = ({
  headline,
  body,
}) => {
  return (
    <BlockContainer noPaddings>
      <Flex
        sx={{
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          paddingBottom: ["0vh", "4vh"],
          paddingTop: ["20vh", "25vh"]
        }}
      >
        <Box sx={{ maxWidth: "700px" }}>
          <Heading
            as="h2"
            sx={{
              fontFamily: "Ohno Blazeface",
              fontSize: ["40px", "40px", "50px", "50px"],
              letterSpacing: "0px",
              textAlign: "center",
            }}
            variant="h2"
          >
            {headline}
          </Heading>
          <Text sx={{ mt: 4, textAlign: "center" }}>{body}</Text>
        </Box>
      </Flex>
    </BlockContainer>
  )
}

export default ContentfulTextHeroBlock
