import React, { useContext } from "react"
import { Box, Flex, Heading, Text } from "theme-ui"

import AppContext from "../app-context"
import BlockContainer from "./block-container"

const ContentfulSingleSlideBlock = ({
  body,
  headline,
  picture,
  tags = [],
}) => {
  const { setIsContactModalVisible } = useContext(AppContext)

  return (
    <BlockContainer>
      <Flex
        sx={{
          alignItems: "center",
          flexDirection: ["column-reverse", "column-reverse", "row", "row"],
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            flex: 0.4,
            pr: [0, 0, 4, 4],
            pt: [4, 4, 0, 0],
          }}
        >
          <Heading
            as="h2"
            sx={{ textAlign: ["center", "center", "right", "right"] }}
            variant="h2"
          >
            {headline}
          </Heading>
          <Text
            sx={{ mt: 3, textAlign: ["center", "center", "right", "right"] }}
          >
            {body}
          </Text>
          <Box sx={{ mt: 3 }}>
            {tags?.length === 1 ? (
              <Text
                sx={{ textAlign: ["center", "center", "right", "right"] }}
                variant="caption2"
              >
                {tags[0]}
              </Text>
            ) : (
              tags?.map((tag) => <Text variant="caption2">+ {tag}</Text>)
            )}
          </Box>
          <Text
            onClick={() => {
              setIsContactModalVisible(true)
            }}
            sx={{
              cursor: "pointer",
              textAlign: ["center", "center", "right", "right"]
            }}
            variant="caption2"
          >
            Contact us
          </Text>
        </Box>
        <Box sx={{ flex: 0.4 }}>
          <img
            alt={headline}
            src={picture?.resize?.src}
            style={{ minHeight: "70vh", objectFit: "cover", width: "100%" }}
          />
        </Box>
      </Flex>
    </BlockContainer>
  )
}

export default ContentfulSingleSlideBlock
